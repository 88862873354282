import React, {useState} from 'react';
import ReactModal from 'react-modal';
import sendEmail from "../../scripts/sendEmail";
import {
    ArtworkAdditionalInfo,
    ArtworkBasicInfo,
    ArtworkInfoContainer,
    ArtworkInfoListHeader,
    ArtworkInfoUnorderedList,
    ArtworkInfoAuthor,
    ArtworkInfoTitle,
    ArtworkInfoParagraph,
    ArtworkInfoButtonContainer,
    ArtworkInfoButton, Field, Form, ErrorMessage, SuccessMessage, ArtworkInfoPrice,
} from './styledElements';
import {sliceTitle} from "../../helpers/sliceTitle";
import {displayPrice} from "../../helpers/displayPrice";

const emailRegex = /\S+@\S+\.\S+/;

const customStyles = {
    overlay: {
        ...ReactModal.defaultStyles.overlay,
        position: 'fixed',
        top: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        background: 'rgba(0, 0, 0, 0.85)',
        zIndex: 1,
        opacity: 0,
        transition: 'all 500ms ease-in-out',
    },
    content: {
        ...ReactModal.defaultStyles.content,
        inset: 0,
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        position: 'relative',
        width: '100%',
        maxWidth: '500px',
        maxHeight: '100%',
        padding: 0,
        background: '#fff',
        overflow: 'auto',
        transform: 'translateY(-100%) !important',
        transition: 'all 500ms ease-in-out',
    }
};

const AdditionalInfoElement = ({ additionalInfo, label }) => additionalInfo && (
    <>
        <ArtworkInfoListHeader>
            {label}
        </ArtworkInfoListHeader>
        <ArtworkInfoUnorderedList>
            {additionalInfo.split(';').map((additionalInfoItem, index) => additionalInfoItem.trim().length > 0 && (
                <li key={index}>
                    {`${additionalInfoItem}`}
                </li>
            ))}
        </ArtworkInfoUnorderedList>
    </>
);

const getEmailSettings = (artwork, exhibition, contactEmail) => ({
    contactEmail,
    body: 'Hello, I\'m interested in this work. Could you please provide more information about the piece?',
    subject: `[Inquiry] ${exhibition.title} ${exhibition.subtitle}: ${sliceTitle(artwork.artwork_title)}`
});

const Info = ({ artwork, exhibition, contactEmail, documentSource, showPrice, disableArrowNav, firstImagePath }) => {
    const {
        artist,
        artwork_title,
        date_of_creation,
        technique,
        signature,
        dimensions,
        provenance,
        exhibitions,
        literature,
        price,
        currency,
        additional_info,
        status,
        description
    } = artwork;
    const { contactEmail: to, subject, body } = getEmailSettings(artwork, exhibition, contactEmail);
    const [modalOpened, setModalOpened] = useState(false);
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [newsubject, setNewsubject] = useState(subject);
    const [newsubjectError, setNewsubjectError] = useState(false);
    const [message, setMessage] = useState(body);
    const [messageError, setMessageError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [firstValidation, setFirstValidation] = useState(false);
    const foundArtwork = {fullname: artist, title: artwork_title};
    const [disableButton, setDisableButton] = useState(false);

    const validate = () => {
        let counter = 0;

        if (name.trim() === '') {
            setNameError(true);
            counter++;
        } else {
            setNameError(false);
        }
        if (email.trim() === '') {
            setEmailError(true);
            counter++;
        } else {
            if (!emailRegex.test(email.trim())) {
                setEmailError(true);
            } else {
                setEmailError(false);
            }
        }
        if (newsubject.trim() === '') {
            setNewsubjectError(true);
            counter++;
        } else {
            setNewsubjectError(false);
        }
        if (message.trim() === '') {
            setMessageError(true);
            counter++;
        } else {
            setMessageError(false);
        }

        return !counter;
    };

    return (
        <ArtworkInfoContainer>
            <ArtworkBasicInfo>
                <ArtworkInfoAuthor>{artist}</ArtworkInfoAuthor>
                <ArtworkInfoTitle>{sliceTitle(artwork_title)}</ArtworkInfoTitle>
                <ArtworkInfoParagraph>{date_of_creation}</ArtworkInfoParagraph>
                <ArtworkInfoParagraph>{technique}</ArtworkInfoParagraph>
                <ArtworkInfoParagraph>{signature}</ArtworkInfoParagraph>
                <ArtworkInfoParagraph>{dimensions}</ArtworkInfoParagraph>
                <ArtworkInfoParagraph dangerouslySetInnerHTML={{ __html: additional_info }} />
                { showPrice && parseInt(price, 10) ? <ArtworkInfoPrice>{status === 'reserved' ? 'RESERVED, ' : null}{displayPrice(price)} {(currency && String(currency).toUpperCase()) || 'EUR'} excl. taxes</ArtworkInfoPrice> : null }
                <ArtworkInfoButtonContainer>
                    {status === 'available' && (
                      <ArtworkInfoButton type={'primary'} onClick={() => {
                          setModalOpened(true);
                          disableArrowNav(true);
                      }}>
                          Inquire
                      </ArtworkInfoButton>
                    )}
                    {status === 'sold' && (
                      <ArtworkInfoButton type={'primary'} onClick={() => {}} disabled>
                          Sold
                      </ArtworkInfoButton>
                    )}
                    {status === 'reserved' && (
                      <ArtworkInfoButton type={'primary'} onClick={() => {}} disabled>
                          Reserved
                      </ArtworkInfoButton>
                    )}
                    {artwork.external_link ? <ArtworkInfoButton type={'secondary'} as="a"
                       href={`${artwork.external_link}`}
                       target="_blank"
                       rel="noopener noreferrer"
                       centered
                    >Read more
                    </ArtworkInfoButton> : null }
                </ArtworkInfoButtonContainer>
            </ArtworkBasicInfo>
            <ArtworkAdditionalInfo>
                <ArtworkInfoParagraph>{description}</ArtworkInfoParagraph>
                <AdditionalInfoElement
                    additionalInfo={exhibitions}
                    label={'Exhibitions'}
                />
                <AdditionalInfoElement
                    additionalInfo={provenance}
                    label={'Provenance'}
                />
                <AdditionalInfoElement
                    additionalInfo={literature}
                    label={'Literature'}
                />
            </ArtworkAdditionalInfo>
            <ReactModal
                isOpen={modalOpened}
                onRequestClose={() => {
                    setModalOpened(false);
                    disableArrowNav(false);
                }}
                style={customStyles}
                contentLabel="Inquire"
                closeTimeoutMS={500}
                openTimeoutMS={500}
            >
                <Form className="form">
                    <button id="close-inquire-modal" className="small-btn" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setModalOpened(false);
                        disableArrowNav(false);
                        setSuccess(false);
                        setError(false);
                        setName('');
                        setEmail('');
                        setMessage(body);
                        setNewsubject(subject);
                        setEmailError(false);
                        setNewsubjectError(false);
                        setMessageError(false);
                        setNameError(false);
                        setFirstValidation(false);
                    }}>
                        <img src="/assets/ico-close.svg" alt="close" />
                    </button>

                    <h2>Inquire</h2>

                    <Field size="short" error={nameError}>
                        <label htmlFor="name">Name *</label>
                        <input type="text"
                               id="name"
                               name="name"
                               placeholder="Name"
                               value={name}
                               onChange={(e) => {
                                   setName(e.target.value);
                                   if(firstValidation) {
                                       validate();
                                   }
                               }}
                        />
                        <span className="error">This field is required</span>
                    </Field>
                    <Field error={emailError} email={email.trim()}>
                        <label htmlFor="email">Email *</label>
                        <input type="text" id="email" name="email" placeholder="Your email"
                               value={email}
                               onChange={(e) => {
                                   setEmail(e.target.value);
                                   if(firstValidation) {
                                       validate();
                                   }
                               }}
                        />
                        <span className="error" id="empty-email">This field is required</span>
                        <span className="error" id="wrong-email">Please provide a valid email address</span>
                    </Field>
                    <Field error={newsubjectError}>
                        <label htmlFor="subject">Subject *</label>
                        <input type="text" id="subject" name="subject" placeholder="Subject"
                               value={newsubject}
                               onChange={e => {
                                   setNewsubject(e.target.value);
                                   if(firstValidation) {
                                       validate();
                                   }
                               }}
                        />
                        <span className="error">This field is required</span>
                    </Field>

                    <Field error={messageError}>
                        <label htmlFor="message">Message *</label>
                        <textarea id="message" placeholder="Message"
                                  value={message}
                                  onChange={(e) => setMessage(e.target.value)}
                        />
                        <span className="error">This field is required</span>
                    </Field>

                    <button
                        className="action-button"
                        id="send-inquire"
                        disabled={disableButton}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            setFirstValidation(true);

                            if (validate()) {
                                setDisableButton(true);

                                sendEmail({
                                    name: name.trim(),
                                    email: email.trim(),
                                    subject: newsubject.trim(),
                                    message: message.trim(),
                                    ...foundArtwork,
                                    to: to,
                                    attachments: firstImagePath ? [firstImagePath] : [],
                                }).then((res) => {
                                    setError(false);
                                    setSuccess(true);
                                    setName('');
                                    setEmail('');
                                    setMessage('');
                                    setNewsubject('');
                                    setDisableButton(false);
                                }).catch((error) => {
                                    setError(true);
                                    setDisableButton(false);
                                });
                            }
                        }}
                    >Inquire</button>
                    {error ? <ErrorMessage>
                        Some problem occurred. Please try again later.
                    </ErrorMessage> : null}
                    {success ? <SuccessMessage>
                        Your message was send. Thank you.
                    </SuccessMessage> : null}
                </Form>
            </ReactModal>
        </ArtworkInfoContainer>
    );
};

export default Info;
